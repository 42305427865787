/* eslint-disable prettier/prettier */
/* eslint-disable no-return-assign */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React from "react";
import "./RoundedInputField.scss";
import classNames from "classnames";

type RoundedInputFieldProps = {
  id: string;
  type?: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
  icon?: React.ReactElement;
  state?: "Valid" | "Invalid" | "NotSet";
  callback: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkState?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export type RoundedInputFieldState = {
  focused: boolean;
};

/**
 * Default input field.
 *
 * Note: `checkState` is a callback that can be set to receive
 * updates when the component focuses or blurs.
 */
export class RoundedInputField extends React.Component<
  RoundedInputFieldProps,
  RoundedInputFieldState
> {
  private rootRef = React.createRef<HTMLDivElement>();

  private inputRef = React.createRef<HTMLInputElement>();

  static defaultProps = {
    icon: "",
    value: "",
    error: "",
    type: "text",
    disabled: false,
    state: "NotSet",
    placeholder: "",
    autoComplete: "on",
  };

  constructor(props: RoundedInputFieldProps) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  // #region Methods

  onFocus = () => {
    this.setState({ focused: true });
    this.inputRef.current?.focus();
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ focused: false });
    if (this.props.checkState) {
      this.props.checkState(e);
    }
  };

  onBoxClick = () => {
    this.inputRef.current?.focus();
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.callback(e);
    if (this.props.checkState) {
      this.props.checkState(e);
    }
  };

  // #endregion

  render() {
    return (
      <div ref={this.rootRef} className="rounded-form-field">
        <div
          role="button"
          tabIndex={-1}
          onKeyDown={this.onBoxClick}
          onClick={this.onBoxClick}
          className={classNames([
            "rounded-form-field-input-rounded",
            this.state.focused && "focused",
            this.props.state === "Valid" && "valid",
            this.props.state === "Invalid" && "invalid",
          ])}
        >
          <input
            id={this.props.id}
            ref={this.inputRef}
            onBlur={this.onBlur}
            type={this.props.type}
            onFocus={this.onFocus}
            onChange={this.onChange}
            value={this.props.value}
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
            autoComplete={this.props.autoComplete?.toString() || "false"}
          />
          {this.props.icon}
        </div>
        {this.props.error !== "" && (
          <p className="input-error">{this.props.error}</p>
        )}
      </div>
    );
  }
}

export default React.forwardRef<RoundedInputField, RoundedInputFieldProps>(
  (props, ref) => <RoundedInputField ref={ref} {...props} />
);
