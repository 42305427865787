import React from "react";
import "./styles.scss";
import { ErrorContentProps } from "./types";

const ErrorContent = (props: ErrorContentProps) => {
  const { title, subTitle, message, actionUrl, actionTitle } = props;
  return (
    <div className="error-container">
      <h1>{title}</h1>
      <p className="sub-title">{subTitle}</p>
      {message && <p className="text-base">{message}</p>}
      <div className="cta-container">
        <a href={actionUrl} className="cta">
          {actionTitle}
        </a>
      </div>
    </div>
  );
};

ErrorContent.defaultProps = {
  title: "Whoops!",
  subTitle: "Something went wrong.",
};

export default ErrorContent;
