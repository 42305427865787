/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/gatsby";
import {
  AllFields,
  TimeoutRef,
  ConsentError,
  TrackingParams,
  FieldValidation,
  ValidateFormVals,
} from "../../../types/index";
import {
  isEmailValid,
  formatUSPhone,
  isFullNameValid,
  isPhoneNumberValid,
} from "../../utils/helpers";
import {
  TERMS_URL,
  CLEVER_URL,
  PRIVACY_URL,
  CONSENT_URL,
  INVALID_EMAIL,
  INVALID_FULL_NAME,
  INVALID_PHONE_NUMBER,
} from "../../utils/constants";
import "./styles.scss";
import SVGIcon from "../../components/SVGIcon";
import CleverLogo from "../../images/cleverLogoBlue.svg";
import AuthenticationLayout from "../../components/AuthenticationLayout";
import RoundedInputField, {
  RoundedInputField as RoundedInputFieldType,
} from "../../components/Fields/RoundedInputField";
import ErrorContent from "../../components/ErrorContent";

const Index = () => {
  const [email, setEmail] = useState("");
  const [saving, setSaving] = useState(false);
  const debounceRef = useRef<TimeoutRef>(null);
  const [fullName, setFullName] = useState("");
  const [success, setSuccess] = useState(false);
  const [magicUuid, setMagicUuid] = useState("");
  const [contactId, setContactId] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const emailRef = useRef<RoundedInputFieldType>(null);
  const [submittable, setSubmittable] = useState(false);
  const [error, setError] = useState<ConsentError>(null);
  const fullNameRef = useRef<RoundedInputFieldType>(null);
  const phoneNumberRef = useRef<RoundedInputFieldType>(null);

  const [emailValidation, setEmailValidation] = useState<FieldValidation>({
    valid: "NotSet",
    field: "email",
  });
  const [phoneNumberValidation, setPhoneNumberValidation] =
    useState<FieldValidation>({ valid: "NotSet", field: "phoneNumber" });
  const [fullNameValidation, setFullNameValidation] = useState<FieldValidation>(
    { valid: "NotSet", field: "fullName" }
  );

  useEffect(() => {
    try {
      const defaultValues = getDefaultValues();
      setEmail(defaultValues.email);
      setFullName(defaultValues.fullName);
      setMagicUuid(defaultValues.magicUuid);
      setContactId(defaultValues.contactId);

      const _fmtPhone = formatUSPhone(defaultValues.phoneNumber, true);
      setPhoneNumber(_fmtPhone);

      debounceAction(() => {
        canSubmit({
          email: defaultValues.email,
          fullName: defaultValues.fullName,
          phoneNumber: defaultValues.phoneNumber,
        });
      });

      logEvent({
        category: "Consent",
        action: "Loaded",
        nonInteraction: true,
      });
    } catch (error: any) {
      const message = error?.message || error;
      handleError({ title: "Could not initialize.", message });

      logEvent({
        category: "Consent",
        action: "Loading Failed",
        nonInteraction: true,
      });
    }
    return () => {
      clearDebounce();
    };
  }, []);

  // #region Callback

  function onFullNameChanged(e: any) {
    const _value = e.target.value;
    setFullName(_value);
    if (fullNameValidation.valid === "Invalid") {
      validateFullName(_value);
      onUpdateCheckValidation("fullName");
    }
  }

  function onEmailChanged(e: any) {
    const _value = e.target.value;
    setEmail(_value);
    if (emailValidation.valid === "Invalid") {
      validateEmail(_value);
      onUpdateCheckValidation("email");
    }
  }

  function onPhoneNumberChanged(e: any) {
    const _value = e.target.value;
    const _fmtPhone = formatUSPhone(_value, true);
    setPhoneNumber(_fmtPhone);
    if (phoneNumberValidation.valid === "Invalid") {
      validatePhoneNumber(_value);
      onUpdateCheckValidation("phoneNumber");
    }
  }

  // #endregion

  // #region Validation

  function validateFullName(value?: string) {
    const _valid = isFullNameValid(value);
    setFullNameValidation({
      field: "fullName",
      valid: _valid ? "Valid" : "Invalid",
      error: !_valid ? INVALID_FULL_NAME : "",
    });
    return _valid;
  }

  function validateEmail(value?: string) {
    const _valid = isEmailValid(value);
    setEmailValidation({
      field: "email",
      valid: _valid ? "Valid" : "Invalid",
      error: !_valid ? INVALID_EMAIL : "",
    });
    return _valid;
  }

  function validatePhoneNumber(value?: string) {
    const _valid = isPhoneNumberValid(value, "1");
    setPhoneNumberValidation({
      field: "phoneNumber",
      valid: _valid ? "Valid" : "Invalid",
      error: !_valid ? INVALID_PHONE_NUMBER : "",
    });
    return _valid;
  }

  /**
   * Called after a field is updated. Enables submittable if all other fields,
   * that is not the field passed into thif function, are valid.
   * @param {AllFields} field
   */
  function onUpdateCheckValidation(field: AllFields) {
    const allValidation = [
      emailValidation,
      fullNameValidation,
      phoneNumberValidation,
    ];

    let currentlyValid = true;
    allValidation.forEach(v => {
      if (v.field !== field) {
        if (v.valid === "Invalid") {
          currentlyValid = false;
        }
      }
    });
    setSubmittable(currentlyValid);
  }

  /**
   * Full form validation, and then sets to the focus to the first
   * invalid field if one exists.
   * @param {ValidateFormVals} values
   */
  function canSubmit(values?: ValidateFormVals) {
    // Having an issue setting the 'RoundedInputFieldType' type here.
    const arrInvalid: any[] = [];

    let _submittable = false;
    const _email = values?.email || email;
    const _fullName = values?.fullName || fullName;
    const _phoneNumber = values?.phoneNumber || phoneNumber;

    const _isEmailValid = validateEmail(_email);
    const _isFullNameValid = validateFullName(_fullName);
    const _isPhoneNumberValid = validatePhoneNumber(_phoneNumber);

    if (_isEmailValid && _isFullNameValid && _isPhoneNumberValid) {
      _submittable = true;
    }

    if (!_isFullNameValid) {
      arrInvalid.push(fullNameRef);
    }

    if (!_isEmailValid) {
      arrInvalid.push(emailRef);
    }

    if (!_isPhoneNumberValid) {
      arrInvalid.push(phoneNumberRef);
    }

    setSubmittable(_submittable);
    if (!_submittable && arrInvalid.length) {
      arrInvalid[0].current.onFocus();
    }

    return _submittable;
  }

  // #endregion

  // #region Submittal

  const onConsent = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const _submittable = canSubmit();
    if (_submittable) {
      try {
        setSaving(true);
        const baseUrl = process.env.GATSBY_API_URL;
        const url = `${baseUrl}/contacts/give-opt-in-consent/?magic_uuid=${magicUuid}&contact_id=${contactId}`;
        await axios.get(url);

        logEvent({
          category: "Consent",
          action: "Consented",
        });
        setSuccess(true);
      } catch (error: any) {
        const message = error.message || error;
        setError({ title: "Processing consent failed.", message });
        logEvent({
          category: "Consent",
          action: "Failed",
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const onBackToClever = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.location.href = CLEVER_URL;
  };

  // #endregion

  // #region Misc

  function clearDebounce() {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current as number);
      debounceRef.current = null;
    }
  }

  function handleError(error: ConsentError) {
    if (error) {
      console.error("Error", error.title, error.message);
      Sentry.captureException(error);
      setError(error);
    }
  }

  /**
   * Collects our default values from the url parameters.
   */
  function getDefaultValues() {
    const urlParams = new URLSearchParams(window.location.search);
    const _email = urlParams.get("email") || "";
    const _magicUuid = urlParams.get("magic_uuid");
    const _contactId = urlParams.get("contact_id");
    const _fullName = urlParams.get("full_name") || "";
    const _phoneNumber = urlParams.get("phone_number") || "";

    if (!_magicUuid) {
      throw new Error("Magic Link is empty.");
    }

    if (!_contactId) {
      throw new Error("Contact Id is empty.");
    }

    // -----------------------------------------------
    // [JC_3/29/24]: remove if fields become editable.
    // -----------------------------------------------

    if (!_fullName) {
      throw new Error("Full name is empty.");
    }

    if (!_email) {
      throw new Error("Email is empty.");
    }

    if (!_phoneNumber) {
      throw new Error("Phone number is empty.");
    }

    // -----------------------------------------------

    const intContactId = parseInt(_contactId, 10);
    if (Number.isNaN(intContactId)) {
      throw new Error("Contact Id is not an Integer.");
    }

    return {
      magicUuid: _magicUuid,
      contactId: intContactId,
      phoneNumber: _phoneNumber,
      fullName: _fullName,
      email: _email,
    };
  }

  function debounceAction(action: () => void, delay = 250) {
    if (!action) {
      return;
    }
    clearDebounce();
    debounceRef.current = setTimeout(() => {
      action();
    }, delay);
  }

  /**
   * Currently used to send an event to Google Analytics (GA)
   * using the react-ga library.
   * @param {TrackingParams} params
   */
  function logEvent(params: TrackingParams) {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event(params);
    }
  }

  // #endregion

  if (error) {
    return (
      <ErrorContent
        actionTitle="Back to Clever"
        subTitle={error.title}
        message={error.message}
        actionUrl={document.referrer}
      />
    );
  }

  if (success) {
    return (
      <AuthenticationLayout footer flex consent>
        <div className="consent">
          <form className="container" onSubmit={onBackToClever}>
            <img src={CleverLogo} alt="Clever Logo" className="logo" />
            <h2>You&apos;re all set!</h2>
            <button type="submit">Back to Clever</button>
          </form>
        </div>
      </AuthenticationLayout>
    );
  }

  return (
    <AuthenticationLayout footer flex consent>
      <div className="consent">
        <form className="container" onSubmit={onConsent}>
          <img src={CleverLogo} alt="Clever Logo" className="logo" />
          <h2>Clever needs your consent:</h2>
          <ul>
            <li>
              <RoundedInputField
                disabled
                type="text"
                id="fullName"
                value={fullName}
                ref={fullNameRef}
                autoComplete="name"
                placeholder="Full name"
                callback={onFullNameChanged}
                icon={<SVGIcon type="person" />}
                state={fullNameValidation.valid}
                error={fullNameValidation.error}
                checkState={() => validateFullName(fullName)}
              />
            </li>
            <li>
              <RoundedInputField
                disabled
                id="email"
                type="email"
                value={email}
                ref={emailRef}
                autoComplete="email"
                callback={onEmailChanged}
                state={emailValidation.valid}
                error={emailValidation.error}
                placeholder="Enter your email"
                icon={<SVGIcon type="email" />}
                checkState={() => validateEmail(email)}
              />
            </li>
            <li>
              <RoundedInputField
                disabled
                id="phone"
                type="tel"
                autoComplete="tel"
                value={phoneNumber}
                ref={phoneNumberRef}
                placeholder="Phone number"
                callback={onPhoneNumberChanged}
                icon={<SVGIcon type="phone" />}
                state={phoneNumberValidation.valid}
                error={phoneNumberValidation.error}
                checkState={() => validatePhoneNumber(phoneNumber)}
              />
            </li>
          </ul>
          <p>
            By clicking &quot;Consent&quot; I opt-in to receive calls, email,
            SMS, and agree to{" "}
            <a target="_blank" rel="noreferrer" href={TERMS_URL}>
              Clever’s Terms of Use
            </a>
            {", "}
            <a target="_blank" rel="noreferrer" href={PRIVACY_URL}>
              Privacy Policy
            </a>
            {", and "}
            <a target="_blank" rel="noreferrer" href={CONSENT_URL}>
              Consent to Contact Customer
            </a>
            . Message and data rates apply. Message frequency varies. Text STOP
            to cancel. Text or call <a href="tel:18332253837">1-833-225-3837</a>{" "}
            for help.
          </p>
          <button type="submit" disabled={!submittable || saving}>
            {saving ? "Saving..." : "Consent"}
          </button>
        </form>
      </div>
    </AuthenticationLayout>
  );
};

export default Index;
